import http from './http';

import type { HomeSwiper } from './model.type';

const base_path = '/home_swiper';

const update_sort = (data: HomeSwiper[] ) => {
    return http.post(`${base_path}/update_sort`,  {items: data})
}

export default {
    update_sort
}
