import http from './http';

import type { PageQuery, GetByID } from './http_types';

import type { Home, News } from './model.type';

const base_path = '/home';

const getByHomeID = (home_id : string) => {
    return http.get(`${base_path}/get_by_home_id/${home_id}`)
}

const UpdateRelation = (data : {
        home_id: number,
        ids: number[],
    }) => {
        return http.post(`${base_path}/update_relation`, data)

}

// const update_sort = (data : News[]) => {
//     return http.post(`${base_path}/home_news/update_sort`, {items: data})
// }

export default {
    // update_sort,
    UpdateRelation,
    getByHomeID
}
