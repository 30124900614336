import auto from "./_api.auto"

import login from "./login";

import route from "./api_route";

import wxamp from "./api_wxamp";

import file from "./api_file";

import wxamp_user from "./api_wxamp_user";

import home_swiper from "./api_home_swiper";

import home_news from "./api_home_news";

import home from "./api_home";

export default {
    file,
    wxamp,
    login,
    auto,
    route,
    wxamp_user,
    home_swiper,
    home_news,
    home,
}
