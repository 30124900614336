import http from './http';

import type { PageQuery, GetByID } from './http_types';

import type { WxampUser } from './model.type';

const base_path = '/wxamp_user';

const getByAppID = (appid : string, q: PageQuery) => {
    return http.get(`${base_path}/get_by_appid/${appid}?${http.pageToUrlParams(q)}`)
}

export default {
    getByAppID,

};
